import React from "react";
import AboutboxImg from '../../assets/about-image.webp';

const AboutUs = () => {

    return (<>
        <section className="about_section-1">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="section-title col-md-12 mb-4">
                        <p>- Get the Road Service -</p>
                        <h2>How Can We Help You?</h2>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="about_service h-100">
                            <span className="ico-high">
                                <i className="fa fa-cogs" title="choose-your-service"></i>
                            </span>
                            <h2>Choose your service</h2>
                            <p>The first step is to select the category of service you require: truck repair, trailer repair, towing, or a
                                nearby truck stop. You can easily find whatever you're looking for on Online Road Service. We are here to
                                assist you fast and efficiently.</p>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="about_service h-100">
                            <span className="ico-high">
                                <i className="fa fa-street-view" title="where-you-are"></i>
                            </span>
                            <h2>Where you are?</h2>
                            <p>Choose your location to get the service. Online road service serves you with reliable data of service
                                providers near you throughout the United States. You can rest assured that you will receive the road
                                service you want, regardless of where you are.</p>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="about_service h-100">
                            <span className="ico-high">
                                <i className="fa fa-flag" title="suits-you-the-best"></i>
                            </span>
                            <h2>Suits you the best</h2>
                            <p>For the service you choose, Online Road Service offers a list of multiple repair shops and service
                                providers near you. You'll obtain their contact information as well as the distance between you and them.
                                You can choose the service provider that suits your needs the best.</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="about_section-2">
            <div className="container">
                <div className="row justify-content-center align-items-center">

                    <div className="col-md-6">
                        <div className="about_img_box">
                            <img src={AboutboxImg} className='img-fluid' alt='Stuck-in-an-emergency'/>
                        </div>
                    </div>


                    <div className="col-md-6 about-col-text text-left">
                        <h6>We are here to help!</h6>
                        <h3>Stuck In An Emergency?</h3>
                        <h4>"Stay Updated And Ready For Any Emergency With Online Road Services As Your Companion."</h4>
                        <p>Got a tire blowout? Do you need emergency reefer repair? Are you looking for the nearest truck stop?
                            No matter what leaves you stranded, or whatever your breakdown service is. You will find the information
                            you require on Online road service. We understand the distress,which is why we provide you with the
                            necessary assistance instantly. You can count on us to get you back moving as soon as possible.</p>
                    </div>
                </div>
            </div>
        </section>

    </>
    );
};

export default AboutUs;
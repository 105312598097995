import React, { useState } from "react";
import ComponenetHeader from "../common/ComponenetHeader";
import EmailService from "../../service/EmailService";
import { Alert, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

const ContactUs =()=>{
    const [formData, setFormData] = useState({name: '', email: '', phone: '', formMessage:'' });
    const [message, setMessage] = useState({ text: '', variant: '' });
    const [loading, setLoading] = useState(false);

    const handleChange =(e) =>{
      const {name, value} = e.target;
      setFormData({ ...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true); // Set loading to true when the request starts
      setMessage({ text: '', variant: '' }); // Clear previous messages

      try {
         const response = await EmailService.sendEmail(formData);
         setMessage({ text: [response], variant: 'success' });
         setFormData({ name: '', email: '', phone: '', formMessage:'' });
      } catch (error) {
         setMessage({ text: `Error: ${error.message}`, variant: 'danger' });
      } finally {
         setLoading(false);
       }
    }
    return(<>

        <Helmet>
            <link rel="canonical" href="/contact"/>
            <title>Get Expert Truck & Trailer Repair – Reach Out Today!</title>
            <meta name="description" content= {"Contact Online Road Services for expert Truck Repair, Reefer Repair, and Trailer Repair. Reliable service to keep you moving—reach out today!"}/>

            <meta property="og:title" content={"Get Expert Truck & Trailer Repair – Reach Out Today!"} />
            <meta property="og:description"  content= {"Contact Online Road Services for expert Truck Repair, Reefer Repair, and Trailer Repair. Reliable service to keep you moving—reach out today!"}/>
         </Helmet>

        <ComponenetHeader
           name={'Contact Us'}
        />

      <section className="contactPage_section">
        <div className="container">
           <div className="row">
              <div className="col-md-12">
                 <div className="contact_title">
                   <h1>We're here for you</h1>
                   <h4>Any questions or remarks? Just write a message !</h4>
                 </div>
              </div>
           </div>
           {message.text && (
            <Alert variant={message.variant} className="mt-2 col-md-8 mx-auto py-2">
               {message.text}
            </Alert>
            )}
           <form onSubmit={handleSubmit}>
           <div className="row justify-content-center mt-4 mb-5">
              <div className="col-md-3">
                 <div className="form-group cont_form">
                 <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  required
                  disabled={loading}
                  />
                 </div>
              </div>
              <div className="col-md-3">
                 <div className="form-group cont_form">
                 <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  required
                  disabled={loading}
                  />
                 </div>
              </div>
              <div className="col-md-3">
                 <div className="form-group cont_form">
                 <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone"
                  required
                  disabled={loading}
                  />
                 </div>
              </div>

              <div className="col-md-9">
                 <div className="form-group cont_form">
                 <textarea
                  className="form-control h-auto"
                  rows="3"
                  cols="50"
                  name="formMessage"
                  value={formData.formMessage}
                  onChange={handleChange}
                  placeholder="Write a message"
                  required
                  disabled={loading}
                  />
                 </div>
              </div>
              <div className="col-md-9">
                 <div className="form_btn mt-1">
                 <button type="submit" className="btn w-25" disabled={loading}>
                     {loading ? (
                        <>
                           <Spinner animation="border" size="sm" role="status" className="me-2" />
                           Sending...
                        </>
                     ) : (
                        'Submit'
                     )}
                  </button>
                 </div>
              </div>
           </div>
           </form>

           <hr/>

           <div className="row justify-content-center contactIcon_outer">
              <div className="col-md-10 contactIcon">
                 <div className="row">
                   <div className="col-md-4">
                      <div className="contactInfo">
                          <div className="icon_Info">
                          <i className="fa fa-phone" title="Phone"></i>
                          </div>
                          <div className="detail_Info">
                            <h3>Phone No.</h3>
                            <h4><a href="tel:+13077760076">307-776-0076</a></h4>
                          </div>
                      </div>
                   </div>

                   <div className="col-md-4">
                      <div className="contactInfo">
                          <div className="icon_Info">
                          <i className="fa fa-envelope" title="Email"></i>
                          </div>
                          <div className="detail_Info">
                            <h3>Email ID</h3>
                            <h4><a href="mailto:info@onlineroadservices.com">info@onlineroadservices.com</a></h4>
                          </div>
                      </div>
                   </div>

                   <div className="col-md-4">
                      <div className="contactInfo">
                          <div className="icon_Info">
                          <i className="fa fa-thumbs-up" title="Follow Us"></i>
                          </div>
                          <div className="detail_Info">
                            <h3>Follow us</h3>
                            <div className="social-Info">
                            <ul>
                            <li><a href="https://www.facebook.com/onlineroadservices" target="_blank"><i className="fab fa-facebook-square" title="facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/onlineroadservices/" target="_blank"><i className="fab fa-instagram" title="instagram"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/online-road-services/about/?viewAsMember=true" target="_blank"><i className="fab fa-linkedin" title="linkedin"></i></a></li>
                            <li><a href="https://in.pinterest.com/onlineroadservices/" target="_blank"><i className="fab fa-pinterest" title="pinterest"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCTo2NL2oYsJYipNUgNJz0fg" target="_blank"><i className="fab fa-youtube" title="youtube"></i></a></li>
                            <li><a href="https://x.com/ORS_SOS" target="_blank"><i className="fab fa-x-twitter" title="twitter"></i></a></li>
                            </ul>
                            </div>
                          </div>
                      </div>
                   </div>



                 </div>
              </div>
           </div>

        </div>
      </section>



      </>
    );
};

export default ContactUs;
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import playstore from '../../assets/googleplay.webp';
import appstore from '../../assets/appstore.webp';
import welcomeimage from '../../assets/welcome-image.webp';

const WelcomeModal = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const hasVisited = sessionStorage.getItem("hasVisited");
    if (!hasVisited) {
      setShow(true);
      sessionStorage.setItem("hasVisited", "true");
    }
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal className="welcomeModal" show={show} onHide={handleClose} backdrop="static">
      <Modal.Header className="pb-0 pt-0 border-0" closeButton>
      </Modal.Header>
      <Modal.Body closeButton>
        <div className="row justify-content-center align-items-center mx-0">
            <div className="col-md-6 pl-md-0 pl-0 pr-md-3 pr-0">
                <div className="welcomeImage">
                 <img src={welcomeimage} className='img-fluid' alt='Mobile App'/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row welcomeContent pb-md-0 pb-3">
                    <div className="col-md-12">
                    <h3>Ready to unlock the world of rewards?</h3>
                    <p>Upload your bill on our App and Start Earning Coins.</p>
                    </div>
                    <div className="col-md-6 px-md-2 pr-md-2 pr-1 col-6">
                        <a target="blank" href="https://apps.apple.com/in/app/online-road-service/id6444897798">
                        <img src={appstore} className='img-fluid' alt='App Store'/>
                        </a>
                    </div>
                    <div className="col-md-6 px-md-2 pl-md-2 pl-1 col-6">
                        <a target="blank" href="https://play.google.com/store/apps/details?id=com.ors.roadservice">
                        <img src={playstore} className='img-fluid' alt='Play Store'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
      </Modal.Body>

    </Modal>
  );
};

export default WelcomeModal;
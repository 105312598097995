import React, { useEffect, useState } from "react";
import BlogService from "../../service/BlogService";

const RelatedBlog=()=>{

    const [relatedBlogs, setRelatedBlogs] = useState([]);

    useEffect(()=>{
        const fetchRandomBlog = async () => {
            try {
                const response = await BlogService.getAllBlogs(0, 3);
                setRelatedBlogs(response.content); // Set the blog data
            } catch (error) {
                console.error('Error fetching Blogs:', error);
            }
          };

        fetchRandomBlog();
    }, []);

       // Function to create a slug from a given string
   const createSlug = (title) => {
    return title
       .toLowerCase() // Convert to lowercase
       .trim() // Trim leading/trailing spaces
       .replace(/\s+/g, '-') // Replace spaces with hyphens
       .replace(/[^\w\-]+/g, '') // Remove all non-word characters
       .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen
       .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
    };

    return(
        <>
        <section className="roadside_nearby related_topic">
        <div className="container">
        <div className="row">
        <div className="col-md-12">
        <h4 className="roadside_title">Latest Blogs</h4>
        </div>
        </div>

        {relatedBlogs.length > 0 &&(
            <div className="row justify-content-center">
            {relatedBlogs.map((blog) => (
            <div className="col-md-4" key={blog.id}>
            <div className="blog_content related_blog_content h-100">
            <h2 className="b-title">
                <a href={`/blog/${blog.id}/${createSlug(blog.title)}`}>{blog.title}</a>
            </h2>
            <span className="b-date"><i className="far fa-calendar" title="date"></i>
                            {new Date(blog.publishedDate).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit'
                            })}
                            </span>
            <p className="b-description">{blog.content.replace(/<\/?[^>]+(>|$)/g, "")}</p>
            <a className="mt-2 d-inline-block font-weight-bold" href={`/blog/${blog.id}/${createSlug(blog.title)}`}>Read More</a>
            </div>
            </div>
        ))}
        </div>
        )}

        </div>
        </section>
        </>
    );
};

export default RelatedBlog;